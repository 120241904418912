import logo from './logo.svg';
import './App.css';
import { useEffect, useRef, useState } from 'react';
import axios from "axios";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { Howl } from "howler";
import MicIcon from '@mui/icons-material/Mic';
import { Button, Input, InputAdornment, TextField, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { format } from 'date-fns';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import MenuIcon from '@mui/icons-material/Menu';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import PublicIcon from '@mui/icons-material/Public';
import MicOffIcon from '@mui/icons-material/MicOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';


const BASE_URL= "https://api.alwayshello.com"

function App() {

  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(false)
  const [canRecord, setCanRecord] = useState(true)
  const [formValue, setFormValue] = useState('');
  const [playSounds, setPlaySounds] = useState(false)
  const [time, setTime] = useState(format(new Date(), 'h:mm'))
  const dummy = useRef();

  setInterval(() => {setTime(format(new Date(), 'h:mm'))}, 1000)


  // Instantiate items for speech to text
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  var recordSFX = new Howl({
      src: [`${BASE_URL}/seanbot/v2/record.mp3`],
      autoplay: false,
    })

  useEffect(() => {
    const sendText = async () => {
      if (listening || !(transcript.length)) {return}
      setFormValue(transcript)
      handleSubmit1()
    };

    sendText()
  }, [listening])

    // Try to get permission to record audio on mounted

  const getConversation = async () => {
    try{
      const resp = await axios.get(`${BASE_URL}/seanbot/v2/conversation`, { withCredentials: true })
      setMessages(resp.data.messages)
      dummy.current.scrollIntoView({ behavior: 'smooth' });
      console.log(dummy.current)
    } catch (err) {
      console.log(err)
    }
  }

  const handleSwitch = () => {
    if (listening) {
      SpeechRecognition.stopListening()
    }else{
      recordSFX.play()
      SpeechRecognition.startListening()
    }
  }

  const handleSubmit1 = async (e) => {
    e?.preventDefault()
    setLoading(true)
    var newMessage = {role: "user", content: formValue}
    setFormValue("")
    setMessages([...messages, newMessage])
    dummy.current.scrollIntoView({ behavior: 'smooth' });
    try{
      if(playSounds){
        console.log("here")
        var sound = new Howl({
          src: [`${BASE_URL}/seanbot/v2/synthesize.mp3?prompt=${formValue}&idx=${messages.length}`],
          autoplay: true,
          xhr: { method: 'GET', withCredentials: true},
          onend: async function () {
            await getConversation();
            setLoading(false)
          }
        })
      }else{
        var resp = await axios.post(`${BASE_URL}/seanbot/v2/chat`, {messages: [...messages, newMessage]}, { withCredentials: true })
        if (resp?.data?.messages){
          setMessages(resp?.data?.messages)
          setLoading(false)
        }
      }
    } catch (err){
      console.log(err)
    } 
  }

  useEffect(() => {
    getConversation()
    // Make sure the browser can handle speech recognition
    if (!browserSupportsSpeechRecognition) {
      setCanRecord(false)
    }
  }, [])

  useEffect(() => {
    setFormValue(transcript)
  }, [transcript])


  function ChatMessage(props) {
    const {content, role} = props.message;

    const messageClass = role === "user" ? 'sent' : 'received';

    return (<>
      <div className={`message ${messageClass}`}>
        <img src={(role !== "user") ? "https://seans-special-bucket.s3.amazonaws.com/logos/sean-face.png" : 'https://cdn-icons-png.flaticon.com/512/222/222358.png'} />
        <p>{content}</p>
      </div>
    </>)
  }

  return (
    <div className='app-wrapper'>
      <div className='title-wrapper'>
        <div className='sean-bot-title'>SeanBot</div>
        <div className='sean-bot-description'>
          Hey there! My name is Sean and I made a copy of myself!
          Using GPT-3.5 and ElevenLabs Synthesis I trained a bot to text and sound like me.
          <br/><br/>
          Test me out by typing a message or switch to audio mode to have a verbal conversation instead.
        </div>
        <div className='social-icons-wrap'>
          <TwitterIcon size="large" className='icon-wrap' onClick={() => window.location.href="https://twitter.com/mangoco_io"} style={{transform: "scale(1.5)"}}/>
          <InstagramIcon size="large" className='icon-wrap' onClick={() => window.location.href="https://www.instagram.com/mangoco.io/"} style={{transform: "scale(1.5)"}}/>
          <PublicIcon size="large" className='icon-wrap' onClick={() => window.location.href="https://seanpmangan.com"} style={{transform: "scale(1.5)"}}/>
        </div>
      </div>

      <div className='chat-wrapper-wrapper'>
        <div className='chat-wrapper'>
          <div className='top-wrapper'>
            <div className='top-top-wrapper'>
              <div className='time'><strong>{time}</strong></div>
              <BatteryChargingFullIcon style={{transform: "scale(1.5) rotate(90deg)"}}/>
            </div>
            <div className='top-bottom-wrapper'>
              <MenuIcon className='menu-btn' style={{transform: "scale(1.7)"}}/>
              <div className='title'><strong>SeanBot V2</strong></div>
            </div>
          </div>
          <div className='message-wrapper'>

            {messages && messages.map((msg, idx) => <ChatMessage key={idx} message={msg} />)}
            {(loading) ? <ChatMessage key={"loading"} message={{role: "assisstant", content: <div className="dot-elastic"/>}}/> : <></>}
            <div ref={dummy} className='dummy'>Empty</div>

          </div>
            <form onSubmit={handleSubmit1}>
              <div className='form-wrapper'>
                <div className='input-wrapper'>
                    <Input
                        type="text"
                        value={formValue} 
                        onChange={(e) => setFormValue(e.target.value)} 
                        placeholder="Send a chat to Sean" 
                        className='text-field'
                        inputProps={{disableUnderline:true}}
                        endAdornment={
                          <InputAdornment position="start">
                            <SendIcon />
                          </InputAdornment>
                        }
                      />
                  </div>
                <button type="submit" className="submit-bt" disabled={!formValue || loading} hidden/>
                <Tooltip title={(canRecord) ? "Record voice" : "Recording is disabled on this browser"}>
                  <Button color="primary" 
                  size="large" 
                  style={{ 
                    color: 'black', 
                    backgroundColor: "White",  
                    filter: "invert(1)", 
                    borderRadius: "10px",
                    boxShadow: "0px 4px 5px rgba(255, 255, 255, .25)"
                  }}
                  onClick={handleSwitch}
                  disabled={!canRecord}
                  endIcon={(listening) ? <MicOffIcon size="large" style={{transform: "scale(1.5)"}}/> : <MicIcon size="large" style={{transform: "scale(1.5)"}}/>}
                  />
                </Tooltip>
                <Tooltip title={(playSounds) ? "Play audio": "Mute audio"}>
                  <Button color="primary" 
                  size="large" 
                  style={{ 
                    color: 'black', 
                    backgroundColor: "White",  
                    filter: "invert(1)", 
                    borderRadius: "10px",
                    boxShadow: "0px 4px 5px rgba(255, 255, 255, .25)"
                  }}
                  onClick={() => setPlaySounds(!playSounds)}
                  endIcon={(playSounds) ? <VolumeUpIcon size="large" style={{transform: "scale(1.5)"}}/> : <VolumeOffIcon size="large" style={{transform: "scale(1.5)"}}/>}
                  />
                </Tooltip>
              </div>
            </form>
        </div>
      </div>
    </div>
  );
}

export default App;
